<template>

  <div>



    <!-- Table Container Card -->
    <b-card class="mb-0">

      <b-row>

        <b-col cols="12" class="mt-1">
          <TableListClients />
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>
  
<script>
import TableListClients from './TableListClients.vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab, BCardImg, BCardFooter, BModal, VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'



export default {
  components: {
    TableListClients,
    BTabs,
    BModal,
    BTab,
    BCard,
    BCardImg,
    BCardFooter,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },




}
</script>
  

  
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
  